import React from "react";
import { Link } from "react-router-dom";
import ProfileIcon from "../assets/images/profile-icon.png";

const ProfileIconHeader = () => {
  return (
    <div className="sticky z-10 left-0 top-0 hidden lg:flex justify-end items-center py-[0.4rem] w-full bg-white">
      <div className="items-center gap-6 px-[3rem]">
        <div className="p-[.2rem] rounded-full">
          <Link to="/profile">
            <img
              src={ProfileIcon}
              alt=""
              className="w-8 h-8 object-contain rounded-full"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileIconHeader;
