import React, { useEffect } from "react";
import { Circle as Progressbar } from "rc-progress";
import { Link, useNavigate } from "react-router-dom";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import ProfileIconHeader from "../../components/ProfileIconHeader";

const SpeakingBandDetails = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth > 1024) {
      navigate("/speaking/test-details/1");
    }
  }, []);
  return (
    <div className="w-full lg:max-h-screen bg-background overflow-scroll pb-5">
      {/* <ProfileIconHeader /> */}
      <div className="flex fixed z-10 w-full px-6 py-3 border-b border-black/10 bg-white">
        <div
          className="flex text-xl items-center gap-2 w-fit cursor-pointer"
          onClick={() => navigate("/speaking/test-details/1")}
        >
          <PiCaretLeftBold />
          <h5 className="">Band Details</h5>
        </div>
      </div>

      <div className="w-full lg:py-[2.2rem] overflow-scroll">


        <div className="px-6 mt-6 pt-11 lg:pt-8 lg:px-[3rem] my-4">
          <div className="mx-auto sm:w-1/2 min-h-[85svh] lg:min-h-[80lvh] relative rounded-3xl bg-gradient-to-b from-primary-500 to-[#64a4f8] p-4">
            <div className="relative w-6/12 lg:w-5/12 mx-auto mt-3">
              <Progressbar
                percent={35}
                strokeWidth={8}
                strokeColor="white"
                trailColor="#F59A1E"
                gapDegree={180}
                trailWidth={8}
              />
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white font-medium">
                3.5 Band
              </span>
            </div>
            <div className="mt-[-2rem] sm:-mt-6">
              <div className="flex flex-col gap-2">
                <div className="p-3 rounded-md bg-[#FFF2F2] text-[.9rem] flex justify-between">
                  <span className="font-medium">Grammer</span>
                  <span className="text-[#FF3B5D]">10 mistakes</span>
                </div>
                <div className="p-3 rounded-md bg-[#FFFAE3] text-[.9rem] flex justify-between">
                  <span className="font-medium">Fluency</span>
                  <span className="text-[#BE8C0C]">9 mistakes</span>
                </div>

                <div className="p-3 rounded-md bg-[#E6FAFB] text-[.9rem] flex justify-between">
                  <span className="font-medium">Pronounciation</span>
                  <span className="text-[#078D92]">98% accurate</span>
                </div>
                <div className="p-3 rounded-md bg-[#EDECFD] text-[.9rem] flex justify-between">
                  <span className="font-medium">Fluency</span>
                  <span className="text-[#281FC9]">76.07% accurate</span>
                </div>
              </div>
              <div className="p-3 mt-12 rounded-xl bg-[#FFF2E9] text-[.9rem] flex flex-col gap-1">
                <span className="font-medium">Suggestions</span>
                <span className="text-[#FF3B5D]">
                  You are giving small answers, if you want to achieve good band
                  score, please try to give long answers
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakingBandDetails;
