import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import ModalWrapper from "./components/ModalWrapper";
import { generateNotificationToken, messaging } from "./utils/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-hot-toast";
import { PrivateRoute } from "./components/ProtectRoutes";
import { useSelector } from "react-redux";
import LoadingSpinner from "./components/LoadingSpinner";
import Question from "./pages/Writing/Pages/components/page/questionRoute";
import ReadingPractice from "./pages/Reading/components/readingPractice";
import SpeakingBandDetails from "./pages/Speaking/SpeakingBandDetails";

const Header = lazy(() => import("./components/Header"));
const Home = lazy(() => import("./pages/Home/Home"));
const BottomTabs = lazy(() => import("./components/BottomTabs"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Stories = lazy(() => import("./pages/Stories/Stories"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Feedback = lazy(() => import("./pages/Feedback/Feedback"));
const Speaking = lazy(() => import("./pages/Speaking/Speaking"));
const Listening = lazy(() => import("./pages/Listening/Listening"));
const Reading = lazy(() => import("./pages/Reading/Reading"));
const Writing = lazy(() => import("./pages/Writing/Writing"));
const WritingQBank = lazy(() => import("./pages/Writing/Pages/WritingQBank"));
const Vocabulary = lazy(() => import("./pages/Vocabulary/Vocabulary"));
const Classes = lazy(() => import("./pages/Classes/Classes"));
const StudentNews = lazy(() => import("./pages/StudentNews/StudentNews"));
const BookIeltsExam = lazy(() => import("./pages/BookIeltsExam/BookIeltsExam"));
const PracticeMockTest = lazy(() =>
  import("./pages/PracticeMockTest/PracticeMockTest")
);
const TaskTwoSubCategoryList = lazy(() =>
  import("./pages/Writing/Pages/TaskTwoSubCategoryList")
);
const TaskTwoWritingQBank = lazy(() =>
  import("./pages/Writing/Pages/TaskTwoWritingQBank")
);
const PrevWritingAnswers = lazy(() =>
  import("./pages/Writing/Pages/components/page/PrevWritingAnswer")
);

const ReadingMaterial = lazy(() =>
  import("./pages/Reading/components/readingMaterial")
);
const Profile = lazy(() => import("./pages/Profile/Profile"));
const SpeakingTestDetails = lazy(() =>
  import("./pages/Speaking/SpeakingTestDetails")
);
const SpeakingQuestions = lazy(() =>
  import("./pages/Speaking/SpeakingQuestions")
);
const SpeakingQuecards = lazy(() =>
  import("./pages/Speaking/SpeakingQuecards")
);
const SpeakingGeneral = lazy(() => import("./pages/Speaking/SpeakingGeneral"));
const SpeakingQuestionDetails = lazy(() =>
  import("./pages/Speaking/SpeakingQuestionDetails")
);
const BuyMembership = lazy(() => import("./pages/BuyMembership/BuyMembership"));

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  const { isLoading } = useSelector((state) => state.other);

  // Generate notification token
  useEffect(() => {
    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        generateNotificationToken();
      } else {
        localStorage.removeItem("fcmToken");
      }
      onMessage(messaging, (payload) => {
        toast(payload.notification.body, {
          icon: payload.notification?.image && (
            <img
              src={payload.notification.image}
              className="w-10 h-10 object-contain"
              alt="icon"
            />
          ),
        });
      });
    };
    requestNotificationPermission();
  }, []);

  const iframeStyle = { border: 'none', width: '100%', height: '100vh', display: 'block'};


  return (
    <div className="text-black bg-white">
      <Suspense fallback={<LoadingSpinner />}>
        <AuthContextProvider>
          <ScrollToTop />
          <Toaster position="top-center" />
          <ModalWrapper>
            {isLoading && <LoadingSpinner />}
            <Routes>



              <Route path="/" element={<iframe src="/InfoWebsite/InfoHome_static.html" style={iframeStyle} title="Home" />} />
              <Route path="/privacy-policy.html" element={<iframe src="/InfoWebsite/privacy-policy.html" style={iframeStyle} title="PrivacyPolicy" />} />
              <Route path="/terms-conditions.html" element={<iframe src="/InfoWebsite/terms-conditions.html" style={iframeStyle} title="TermAndCondition" />} />
              <Route path="/refundandcancellation.html" element={<iframe src="/InfoWebsite/refundandcancellation.html" style={iframeStyle} title="refundandcancellation" />} />




              <Route path="/" element={<Sidebar />}>
                <Route
                  path="/home"
                  element={
                    <>
                      <Header />
                      <Home />
                      <BottomTabs />
                    </>
                  }
                />
                <Route
                  path="/listening"
                  element={
                    <PrivateRoute>
                      <Listening />
                    </PrivateRoute>
                  }
                />{" "}
                <Route
                  path="/reading/:category"
                  element={
                    <PrivateRoute>
                      <Reading />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reading/:category/:material"
                  element={
                    <PrivateRoute>
                      <ReadingMaterial />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/vocabulary"
                  element={
                    <PrivateRoute>
                      <Vocabulary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/stories"
                  element={
                    <PrivateRoute>
                      <Stories />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/shop"
                  element={
                    <PrivateRoute>
                      <Shop />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/feedback"
                  element={
                    <PrivateRoute>
                      <Feedback />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/speaking"
                  element={
                    <PrivateRoute>
                      <Speaking />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                path="/speaking/test-details/:testId"
                element={
                  <PrivateRoute>
                    <SpeakingTestDetails />
                  </PrivateRoute>
                }
              /> */}
                <Route
                  path="/speaking/speaking-questions"
                  element={
                    <PrivateRoute>
                      <SpeakingQuestions />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="/speaking/speaking-questions"
                    element={<SpeakingQuecards />}
                  />
                  <Route
                    path="/speaking/speaking-questions/general"
                    element={<SpeakingGeneral />}
                  />
                </Route>
                <Route
                  path="/speaking/speaking-questions/Question"
                  element={
                    <PrivateRoute>
                      <SpeakingQuestionDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/speaking/speaking-questions/general/Question"
                  element={
                    <PrivateRoute>
                      <SpeakingQuestionDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/speaking/test-details/band-details"
                  element={
                    <PrivateRoute>
                      <SpeakingBandDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reading"
                  element={
                    <PrivateRoute>
                      <Reading />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/buy-membership"
                  element={
                    <PrivateRoute>
                      <BuyMembership />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/writing"
                  element={
                    <PrivateRoute>
                      <Writing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/writing/:category"
                  element={
                    <PrivateRoute>
                      <WritingQBank />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/writing/:category/:subcategory?"
                  element={
                    <PrivateRoute>
                      <TaskTwoWritingQBank />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/writing/:category/:subcategory?/Question"
                  element={
                    <PrivateRoute>
                      <Question />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/writing/:category/tasktwo-subcategories"
                  element={
                    <PrivateRoute>
                      <TaskTwoSubCategoryList />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/writing/:category/:subcategory?/:questionId/prev-answer"
                  element={
                    <PrivateRoute>
                      <PrevWritingAnswers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/classes"
                  element={
                    <PrivateRoute>
                      <Classes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student-news"
                  element={
                    <PrivateRoute>
                      <StudentNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/book-ielts-exam"
                  element={
                    <PrivateRoute>
                      <BookIeltsExam />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/practice-mock-test"
                  element={
                    <PrivateRoute>
                      <PracticeMockTest />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Routes>
          </ModalWrapper>
        </AuthContextProvider>
      </Suspense>
    </div>
  );
}

export default App;
