import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userLoggedIn: !!localStorage.getItem("userData"),
  userDetails: JSON.parse(localStorage.getItem("userData")) || {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const { setUserLoggedIn, setUserDetails } =
  userSlice.actions;
export default userSlice.reducer;
